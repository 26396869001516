import {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
  Ref,
} from "react";
import { Check } from "src/common/icons/FontAwesome/Regular/Check";
import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";
import { InputIcon as InputIconComponent } from "../InputIcon";
import { getHasIcon } from "./get-has-icon";
import styles from "./styles.module.css";

export interface InputProps {
  ["aria-labelledby"]?: string;
  autoFocus?: boolean;
  className?: string;
  error?: string;
  iconLeft?: ReactNode;
  id?: string;
  inputClassName?: string;
  isDisabled?: boolean;
  isValid?: boolean;
  max?: string | number;
  maxLength?: number;
  min?: string | number;
  name: string;
  lines?: number;
  variant?: "default" | "contactForm";
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  type:
    | InputFieldType.CURRENCY
    | InputFieldType.DATE
    | InputFieldType.EMAIL
    | InputFieldType.NUMBER
    | InputFieldType.TEL
    | InputFieldType.TEXT;
  value: string | number | null;
}

function InputInner(
  {
    className,
    error,
    iconLeft,
    inputClassName,
    isDisabled,
    isValid,
    max,
    maxLength,
    min,
    name,
    placeholder,
    type,
    value,
    lines = 1,
    variant = "default",
    ...rest
  }: InputProps,
  ref: Ref<HTMLInputElement>,
) {
  function getType(): HTMLInputTypeAttribute {
    switch (type) {
      case InputFieldType.EMAIL:
        return "email";
      case InputFieldType.CURRENCY:
      case InputFieldType.NUMBER:
        return "number";
      case InputFieldType.DATE:
      case InputFieldType.TEL:
        return "tel";
      case InputFieldType.TEXT:
        return "text";
    }
  }

  const htmlTypeAttribute: HTMLInputTypeAttribute = getType();
  const isDate = type === InputFieldType.DATE;
  const hasIcon = iconLeft || getHasIcon(type);
  const isNumber = type === "number";

  function disableScroll({ target }: FocusEvent<HTMLInputElement>) {
    return target.addEventListener("wheel", (e) => e.preventDefault(), {
      passive: false,
    });
  }

  let variantClassName =
    variant === "contactForm"
      ? "!rounded-[100px] !border-counterGreen-500 text-counterGreen-100 font-medium text-base px-5 !focus-visible:border-white"
      : "";

  return (
    <div className="grid grid-cols-[1fr_max-content] gap-x-2">
      <input
        {...(isNumber ? { onFocus: disableScroll } : {})}
        {...rest}
        aria-invalid={error ? "true" : "false"}
        className={`${styles.input}
        ${isDate && !value ? "text-placeholder invalid:text-black" : ""}
         ${
           hasIcon ? "pl-8" : ""
         } placeholder-placeholder b-2 col-[1/3] row-[1/2] h-12 w-full appearance-none rounded-[10px] border-[1px] bg-transparent px-3 py-2.5 text-left text-lg font-medium tracking-tight text-black focus:outline-none ${
           !!error
             ? "!border-input-error focus-visible:border-input-error"
             : "border-input-default focus-visible:border-input-focus"
         } ${inputClassName ?? ""} ${variantClassName ?? ""}`}
        data-has-value={!!value}
        disabled={isDisabled}
        max={max}
        min={min}
        name={name}
        placeholder={placeholder}
        ref={ref}
        type={htmlTypeAttribute}
        value={value === null ? undefined : value}
      />

      {hasIcon && (
        <div className="pointer-events-none relative left-3 col-[1/3] row-[1/2] flex items-center text-placeholder">
          <div className="inline-grid h-4 w-4 place-items-center [&>*]:max-h-full [&>*]:max-w-full">
            {iconLeft ? iconLeft : <InputIconComponent type={type} />}
          </div>
        </div>
      )}

      <div
        className={`col-[2/3] row-[1/2] grid w-10 items-center justify-center ${
          isValid ? "" : "opacity-0"
        }`}
      >
        <Check className="h-3 w-3 text-emerald-600" />
      </div>
    </div>
  );
}

export const Input = forwardRef(InputInner);
