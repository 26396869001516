import { Calendar } from "src/common/icons/FontAwesome/Regular/Calendar";
import { DollarSign } from "src/common/icons/FontAwesome/Regular/DollarSign";
import { Envelope } from "src/common/icons/FontAwesome/Regular/Envelope";
import { Phone } from "src/common/icons/FontAwesome/Regular/Phone";
import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";

export interface InputIconProps {
  ["aria-label"]?: string;
  type: InputFieldType;
}

export function InputIcon({ type, ...rest }: InputIconProps) {
  switch (type) {
    case InputFieldType.CURRENCY:
      return <DollarSign {...rest} />;
    case InputFieldType.DATE:
      return <Calendar {...rest} />;
    case InputFieldType.EMAIL:
      return <Envelope {...rest} />;
    case InputFieldType.TEL:
      return <Phone {...rest} />;
    default:
      return null;
  }
}
