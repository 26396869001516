import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";

export function getHasIcon(type: InputFieldType) {
  switch (type) {
    case InputFieldType.CURRENCY:
      return true;
    default:
      return false;
  }
}
