import { Paragraph } from "src/common/ui/Paragraph";
import { ReactNode, useEffect, useRef } from "react";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { motion } from "framer-motion";

export enum InlineMessageType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export interface InlineMessageProps {
  children: ReactNode;
  className?: string;
  type: InlineMessageType;
  autoScroll?: boolean;
  animated?: boolean;
}

export function InlineMessage({
  children,
  className,
  type,
  autoScroll = false,
  animated = false,
}: InlineMessageProps) {
  const ref = useRef<HTMLDivElement>(null);
  function getTextColor() {
    switch (type) {
      case InlineMessageType.ERROR:
        return "text-error";
      case InlineMessageType.SUCCESS:
        return "text-success";
      case InlineMessageType.INFO:
        return "text-muted";
    }
  }

  const fontSize = className?.includes("text-") ? "" : "text-xs";

  useEffect(() => {
    if (autoScroll && ref.current) {
      scrollIntoView(ref.current, { block: "center", inline: "center" });
    }
  }, [autoScroll]);

  return (
    <motion.div
      ref={ref}
      initial={animated ? { y: 10, opacity: 0 } : {}}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeOut" }}
    >
      <Paragraph
        className={`${fontSize} whitespace-pre-line font-[500] leading-snug ${getTextColor()} ${className}`}
        role="alert"
      >
        {children}
      </Paragraph>
    </motion.div>
  );
}
