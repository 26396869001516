import { InputFieldLabel } from "src/employee/onboarding-v2/components/types";
import * as yup from "yup";
import { DateTime } from "luxon";
import { isPossibleNumber, validatePhoneNumberLength } from "libphonenumber-js";

const DATE_PAST_LIMIT = new Date("01-01-1920");

export const ACCOUNT_SCHEMA = yup.string().required().label("Account");

export const ADDRESS_CITY_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.ADDRESS_CITY);

export const ADDRESS_COUNTRY_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.ADDRESS_COUNTRY);

export const ADDRESS_POSTAL_CODE_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.ADDRESS_POSTAL_CODE);

export const ADDRESS_STATE_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.ADDRESS_STATE);

export const ADDRESS_STREET_1_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.ADDRESS_STREET_1);

export const ADDRESS_STREET_2_SCHEMA = yup
  .string()
  .trim()
  .label(InputFieldLabel.ADDRESS_STREET_2);

export const AMOUNT_SCHEMA = yup
  .number()
  .required()
  .label(InputFieldLabel.AMOUNT);

export const CHAT_MESSAGE_SCHEMA = yup.string().default("");

export const CONFIRMATION_CODE_SCHEMA = yup
  .string()
  .trim()
  .required("Required")
  .label("Confirmation Code")
  .min(6);

export const DATE_STARTED_SCHEMA = yup
  .string()
  .test({
    skipAbsent: true,
    test(value, ctx) {
      if (!value) return true;

      const today = new Date();
      const date = new Date(value);
      const isValid = DateTime.fromJSDate(date).isValid;

      if (date > today)
        return ctx.createError({ message: "Date needs to be in the past" });
      if (date < DATE_PAST_LIMIT)
        return ctx.createError({ message: "Date needs to be after 1920" });
      if (!isValid) return ctx.createError({ message: "Date is invalid" });

      return true;
    },
  })
  .defined()
  .label("Date started");

export const DOB_SCHEMA = yup
  .string()
  .test({
    skipAbsent: true,
    test(value, ctx) {
      if (!value) return true;

      const today = new Date();
      const date = new Date(value);

      const isValid = DateTime.fromJSDate(date).isValid;

      if (date > today)
        return ctx.createError({ message: "Date needs to be in the past" });
      if (date < DATE_PAST_LIMIT)
        return ctx.createError({ message: "Date needs to be after 1920" });
      if (!isValid) return ctx.createError({ message: "Date is invalid" });

      return true;
    },
  })
  .required("Required")
  .defined()
  .label(InputFieldLabel.DOB);

export const EMAIL_SCHEMA = yup
  .string()
  .trim()
  .required("Required")
  // We're using a custom regex because `yup`'s `.email()`
  // uses a relaxed validation for email addresses
  // that doesn't require top-level domain, like ".com"
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    "Invalid email address",
  )
  .label(InputFieldLabel.EMAIL);

export const FIRST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.FIRST_NAME);

export const LAST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.LAST_NAME);

export const MEMBER_ID_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.MEMBER_ID);

export const PATIENT_AUTHORIZATION_SCHEMA = yup
  .boolean()
  .oneOf([false, true], `Required`)
  .required();

export const PHARMACY_PBM_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.PHARMACY_PLAN);

export const PHONE_COUNTRY_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.PHONE_COUNTRY_CODE);

export const PHONE_NUMBER_SCHEMA = yup
  .string()
  .required("Required")
  .defined()
  .label(InputFieldLabel.MOBILE_PHONE_NUMBER)
  .test(
    "phone-number",
    ({ label }: { label: string; value: string }) => {
      const message = validatePhoneNumberLength(label);

      switch (message) {
        default:
        case "NOT_A_NUMBER":
        case "INVALID_COUNTRY":
        case "INVALID_LENGTH":
          return "Not a valid number";
        case "TOO_SHORT":
          return "Too short";
        case "TOO_LONG":
          return "Too long";
      }
    },
    (value) => isPossibleNumber(value, "US"),
  );

export const PRIMARY_INSURANCE_SCHEMA = yup
  .string()
  .trim()
  .required()
  .label(InputFieldLabel.MEDICAL_PLAN);

export const TEXT_NOTIFICATIONS_SCHEMA = yup
  .boolean()
  .required("Required")
  .oneOf([true], "Required");

export const TERMS_OF_USE_SCHEMA = yup
  .boolean()
  .required("Required")
  .oneOf([true], "Required");

export const DAY_OF_WEEK_SCHEMA = yup
  .string()
  .trim()
  .required("Day of week is required");
export const TIMESLOT_SCHEMA = yup.string().required("Timeslot is required");
export const TIMEZONE_SCHEMA = yup.string().required("Timezone is required");
export const CALL_NOTES_SCHEMA = yup.string();

export const REASON_SCHEMA = yup.string().required("Select the reason.");
export const CONTACT_MESSAGE_SCHEMA = yup
  .string()
  .trim()
  .required("Fill in your message.");
