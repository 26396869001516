import { ChangeEventHandler, forwardRef, ReactNode, Ref } from "react";
import { Heading } from "src/common/ui/Heading";
import { InputFieldType } from "src/employee/onboarding/components/EditConfigContext";
import { InlineMessage, InlineMessageType } from "../InlineMessage";
import { Input } from "../Input";

export interface InputFieldsetProps {
  ["aria-labelledby"]?: string;
  autoFocus?: boolean;
  className?: string;
  error?: string;
  iconLeft?: ReactNode;
  inputClassName?: string;
  isDisabled?: boolean;
  isValid?: boolean;
  label?: string;
  max?: string | number;
  maxLength?: number;
  min?: string | number;
  name: string;
  tabindex?: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type:
    | InputFieldType.CURRENCY
    | InputFieldType.DATE
    | InputFieldType.EMAIL
    | InputFieldType.NUMBER
    | InputFieldType.TEL
    | InputFieldType.TEXT;
  value: string | number | null;
}

function InputFieldsetInner(
  { className, error, label, name, type, ...rest }: InputFieldsetProps,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <fieldset className={`grid gap-y-2 ${className ?? ""}`}>
      <div className="grid gap-y-1">
        {label && (
          <Heading
            className="text-xs font-semibold [word-spacing:0.05rem] uppercase text-gray-950 leading-tight tracking-wide xl:text-sm"
            as="label"
            id={name}
          >
            {label}
          </Heading>
        )}
        <Input
          aria-labelledby={name}
          error={error}
          name={name}
          ref={ref}
          type={type}
          {...rest}
        />
      </div>
      {error && (
        <InlineMessage type={InlineMessageType.ERROR}>{error}</InlineMessage>
      )}
    </fieldset>
  );
}

export const InputFieldset = forwardRef(InputFieldsetInner);
