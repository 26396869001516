"use client";

import {
  EmployeeInformationInputFieldKey,
  InputFieldLabel,
  InputFieldPlaceholder,
} from "src/employee/onboarding-v2/components/types";
import { InputFieldKey } from "src/employee/onboarding/types/form-flow";
import {
  ADDRESS_CITY_SCHEMA,
  ADDRESS_COUNTRY_SCHEMA,
  ADDRESS_POSTAL_CODE_SCHEMA,
  ADDRESS_STATE_SCHEMA,
  ADDRESS_STREET_1_SCHEMA,
  ADDRESS_STREET_2_SCHEMA,
  DOB_SCHEMA,
  EMAIL_SCHEMA,
  FIRST_NAME_SCHEMA,
  LAST_NAME_SCHEMA,
  MEMBER_ID_SCHEMA,
  PRIMARY_INSURANCE_SCHEMA,
  PHARMACY_PBM_SCHEMA,
  PHONE_COUNTRY_SCHEMA,
  PHONE_NUMBER_SCHEMA,
} from "src/common/config/form-validation";
import { createContext, ReactNode, useState } from "react";
import * as yup from "yup";

export const EditConfigContext = createContext<{
  editConfig: FieldGroup | null;
  editFieldSet: (key: FieldGroupName | null) => void;
}>({
  editConfig: null,
  editFieldSet: () => null,
});

export interface Control {
  defaultValue?: string;
  isDisabled?: boolean;
  key: EmployeeInformationInputFieldKey;
  label: string;
  placeholder: string;
  type: InputFieldType;
}

export enum InputFieldType {
  COUNTRY = "country",
  CURRENCY = "currency",
  DATE = "date",
  EMAIL = "email",
  NUMBER = "number",
  STATE = "state",
  TEL = "tel",
  TEXT = "text",
}

export enum FieldGroupName {
  ADDRESS = "address",
  COVERAGE = "coverage",
  DOB = "dob",
  EMAIL = "email",
  NAME = "name",
  PHONE = "phone",
  MEMBER_ID = "memberId",
}

export interface FieldGroup {
  controls: Control[];
  schema: yup.ObjectSchema<Record<string, unknown>>;
  title: string;
  name: FieldGroupName;
}

export function EditConfigProvider({ children }: { children: ReactNode }) {
  const [editConfig, setEditConfig] = useState<FieldGroup | null>(null);

  function editFieldSet(value: FieldGroupName | null) {
    if (value === null) return setEditConfig(null);

    let controls: Control[];
    let name: FieldGroupName = value;
    let schema: any;
    let title: string;

    switch (value) {
      case FieldGroupName.NAME:
        name = value;
        title = "Name";
        controls = [
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.FIRST_NAME,
            label: InputFieldLabel.FIRST_NAME,
            placeholder: InputFieldPlaceholder.FIRST_NAME,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.LAST_NAME,
            label: InputFieldLabel.LAST_NAME,
            placeholder: InputFieldPlaceholder.LAST_NAME,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.FIRST_NAME]: FIRST_NAME_SCHEMA,
            [InputFieldKey.LAST_NAME]: LAST_NAME_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.EMAIL:
        title = "Email";
        controls = [
          {
            type: InputFieldType.EMAIL,
            key: InputFieldKey.EMAIL,
            label: InputFieldLabel.EMAIL,
            placeholder: InputFieldPlaceholder.EMAIL,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.EMAIL]: EMAIL_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.PHONE:
        title = "Phone Number";
        controls = [
          {
            type: InputFieldType.NUMBER,
            key: InputFieldKey.PHONE_COUNTRY_CODE,
            label: InputFieldLabel.PHONE_COUNTRY_CODE,
            placeholder: InputFieldPlaceholder.PHONE_COUNTRY_CODE,
            defaultValue: InputFieldPlaceholder.PHONE_COUNTRY_CODE,
          },
          {
            type: InputFieldType.TEL,
            key: InputFieldKey.PHONE_NUMBER,
            label: InputFieldLabel.MOBILE_PHONE_NUMBER,
            placeholder: InputFieldPlaceholder.PHONE_NUMBER,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.PHONE_COUNTRY_CODE]: PHONE_COUNTRY_SCHEMA,
            [InputFieldKey.PHONE_NUMBER]: PHONE_NUMBER_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.DOB:
        title = "Date of Birth";
        controls = [
          {
            type: InputFieldType.DATE,
            key: InputFieldKey.DOB,
            label: InputFieldLabel.DOB,
            placeholder: InputFieldPlaceholder.DOB,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.DOB]: DOB_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.ADDRESS:
        title = "Personal Info";
        controls = [
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.ADDRESS_STREET_1,
            label: InputFieldLabel.ADDRESS_STREET_1,
            placeholder: InputFieldPlaceholder.ADDRESS_STREET_1,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.ADDRESS_STREET_2,
            label: InputFieldLabel.ADDRESS_STREET_2,
            placeholder: InputFieldPlaceholder.ADDRESS_STREET_2,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.ADDRESS_CITY,
            label: InputFieldLabel.ADDRESS_CITY,
            placeholder: InputFieldPlaceholder.ADDRESS_CITY,
          },
          {
            type: InputFieldType.STATE,
            key: InputFieldKey.ADDRESS_STATE,
            label: InputFieldLabel.ADDRESS_STATE,
            placeholder: InputFieldPlaceholder.ADDRESS_STATE,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.ADDRESS_POSTAL_CODE,
            label: InputFieldLabel.ADDRESS_POSTAL_CODE,
            placeholder: InputFieldPlaceholder.ADDRESS_POSTAL_CODE,
          },
          {
            defaultValue: "US",
            isDisabled: true,
            key: InputFieldKey.ADDRESS_COUNTRY,
            label: InputFieldLabel.ADDRESS_COUNTRY,
            placeholder: InputFieldPlaceholder.ADDRESS_COUNTRY,
            type: InputFieldType.COUNTRY,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.ADDRESS_STREET_1]: ADDRESS_STREET_1_SCHEMA,
            [InputFieldKey.ADDRESS_STREET_2]: ADDRESS_STREET_2_SCHEMA,
            [InputFieldKey.ADDRESS_CITY]: ADDRESS_CITY_SCHEMA,
            [InputFieldKey.ADDRESS_STATE]: ADDRESS_STATE_SCHEMA,
            [InputFieldKey.ADDRESS_POSTAL_CODE]: ADDRESS_POSTAL_CODE_SCHEMA,
            [InputFieldKey.ADDRESS_COUNTRY]: ADDRESS_COUNTRY_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.COVERAGE:
        title = "Coverage Info";
        controls = [
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.MEMBER_ID,
            label: InputFieldLabel.MEMBER_ID,
            placeholder: InputFieldPlaceholder.MEMBER_ID,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.PRIMARY_INSURANCE,
            label: InputFieldLabel.MEDICAL_PLAN,
            placeholder: InputFieldPlaceholder.PRIMARY_INSURANCE,
          },
          {
            type: InputFieldType.TEXT,
            key: InputFieldKey.PHARMACY_PBM,
            label: InputFieldLabel.PHARMACY_PLAN,
            placeholder: InputFieldPlaceholder.PHARMACY_PBM,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.MEMBER_ID]: MEMBER_ID_SCHEMA,
            [InputFieldKey.PRIMARY_INSURANCE]: PRIMARY_INSURANCE_SCHEMA,
            [InputFieldKey.PHARMACY_PBM]: PHARMACY_PBM_SCHEMA,
          })
          .required();

        break;

      case FieldGroupName.MEMBER_ID:
        title = "Member ID";
        controls = [
          {
            type: InputFieldType.NUMBER,
            key: InputFieldKey.MEMBER_ID,
            label: InputFieldLabel.MEMBER_ID,
            placeholder: InputFieldPlaceholder.MEMBER_ID,
          },
        ];
        schema = yup
          .object({
            [InputFieldKey.MEMBER_ID]: MEMBER_ID_SCHEMA,
          })
          .required();

        break;
    }

    const editConfig: FieldGroup = {
      controls,
      name,
      schema,
      title,
    };

    return setEditConfig(editConfig);
  }

  return (
    <EditConfigContext.Provider value={{ editFieldSet, editConfig }}>
      {children}
    </EditConfigContext.Provider>
  );
}
